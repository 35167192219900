<template>
 <div>
  <sdPageHeader title="Selecteer een flow"></sdPageHeader>
  <Main>
   <a-row :gutter="30">
    <template v-if="!loadingFlows && flows.length > 0">
     <a-col v-for="item in flows" :key="item.id" :sm="8" :xs="24">
      <Suspense>
       <template #default>
        <FlowCards :data="item" @set-flow="handleSelectFlow($event)"/>
       </template>
       <template #fallback>
        <sdCards headless>
         <a-skeleton :paragraph="{ rows: 22 }" active/>
        </sdCards>
       </template>
      </Suspense>
     </a-col>
    </template>
    <a-col v-if="loadingFlows" :md="24">
     <div class="spin" style="height:200px">
      <a-spin/>
     </div>
    </a-col>
   </a-row>
   <a-row v-if="!loadingFlows && flows.length < 1" class="w-100">
    <a-col :xs="24">
     <sdAlerts
      :closable="false"
      :outlined="false"
      :showIcon="true"
      description="Er zijn geen flows voor jou beschikbaar."
      message=""
      type="warning"
     />
    </a-col>
   </a-row>
  </Main>
 </div>
</template>

<script>
import {computed, defineComponent, onMounted} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import FlowCards from "./component/FlowCards";
import {Main} from "../styled";

export default defineComponent({
 name: "OrderFlow",
 components: {
  FlowCards,
  Main,
 },
 setup() {
  const router = useRouter()
  const {state, dispatch} = useStore();
  const flows = computed(() => state.flows.flows);

  const loadingFlows = computed(() => state.flows.loadingFlows);
  const handleSelectFlow = async (data) => {
   console.log(data)
   switch (data.config.type) {
    case 'ENERGY':
     await dispatch('setOrderFlow', data);
     await router.push('/order/energy/create');
     break;
    case 'SOLAR':
     await dispatch('setOrderFlowSolar', data);
     await router.push('/order/solar/create');
     break;
    case 'SVM':
     await dispatch('setOrderFlowSVM', data);
     await router.push('/order/svm/create');
     break;
    case 'LEAD':
     await dispatch('setOrderFlowLead', data);
     await router.push(`/order/lead/create/${data.config.productID}`);
     break;
    case 'SHOP':
     if (data.config.subType === 'BATTERYADVANCED') {
      await dispatch('setOrderFlowShop', data);
      await router.push(`/order/battery/create/${data.config.productID}?type=${data.config.subType}`);
      break;
     }
     await dispatch('setOrderFlowShop', data);
     await router.push(`/order/shop/create/${data.config.productID}?type=${data.config.subType}`);
     break;
    case 'SOLARSIMPLE':
     await dispatch('setOrderFlowSolarSimple', data);
     await router.push('/order/solar-simple/create');
     break;
    case 'HYDROMX':
     await dispatch('setOrderFlowSolar', data);
     await router.push('/order/hydromx/create');
     break;
    default:
     break;
   }
  };

  onMounted(async () => {
   await dispatch('cleanFlows');
   await dispatch("cleanGlobalSearch");
   await dispatch('resetCreateOrderConnections');
   await dispatch('resetCreateOrder');
   await dispatch('resetShopOrder');
   await dispatch('resetSVMOrder');
   await dispatch('getFlows');
  });

  return {
   flows,
   handleSelectFlow,
   loadingFlows,
  }
 }
});
</script>

<style scoped>

</style>
